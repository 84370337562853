<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Considering that the
        <stemble-latex content="$\text{K}_\text{w}$" />
        for water at
        <stemble-latex content="$37^\circ\text{C}$" />
        is
        <stemble-latex content="$2.4\times 10^{-14},$" />
        determine the
        <stemble-latex content="$\text{pH}$" />
        of a neutral solution at
        <stemble-latex content="$37^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        b) Is a solution with
        <stemble-latex content="$\text{pH = 7.00}$" />
        acidic, basic, or neutral at
        <stemble-latex content="$37^\circ\text{C?}$" />
      </p>

      <v-radio-group v-model="inputs.natureOfSolution" :disabled="!allowEditing" class="mb-n3 pl-8">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block mb-n3" :value="option.value">
            <template #label>
              <stemble-latex :content="`$\\text{${option.solutionType}}$`" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question137',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
        natureOfSolution: null,
      },
      options: [
        {solutionType: 'Acidic', value: 'Acidic'},
        {solutionType: 'Basic', value: 'Basic'},
        {solutionType: 'Neutral', value: 'Neutral'},
      ],
    };
  },
};
</script>
